import React, { useState } from 'react';
import styled from 'styled-components';
import ModalVideo from 'react-modal-video';
import { getYoutubeId } from '../../../utils/getYoutubeId';

export function HeroNeedsVideo({ component, children, ...rest }) {
  const [play, setPlay] = useState(false);

  function handleChangePlay() {
    setPlay(!play);
  }

  if (component.videoLink) {
    return (
      <StyledContainer onClick={handleChangePlay}>
        {children}
        {typeof window !== 'undefined' && (
          <ModalVideo
            channel="youtube"
            isOpen={play}
            videoId={getYoutubeId(component.videoLink)}
            onClose={handleChangePlay}
          />
        )}
      </StyledContainer>
    );
  }
  return children;
}

const StyledContainer = styled.div`
  cursor: pointer;
  position: relative;
`;
